<template>
  <div id="app" class="container">
    <div class="header" v-if="agent != null">
      <div class="agent">
        <div class="avatar"><img :src="agent.avatar" /></div>
        <div class="name">{{ agent.name}}</div>
      </div>
      <div class="toolbar">
        <a :href="'tel:'+agent.phone"><i class="iconfont icon-phone"></i></a>
        <a href="javascript:;" @click="showQR=true"><i class="iconfont icon-qr"></i></a>
        <a href="javascript:;" @click="toMessage"><i class="iconfont icon-message"></i></a>
        <!-- <router-link to="/agentlist"><i class="iconfont icon-message"></i></router-link> -->
      </div>
    </div>
    <div class="header" v-else>
      <div class="agent">
        <div class="avatar"><i class="iconfont icon-hudongquwei"></i></div>
        <div class="name">南山·凌峯</div>
      </div>
      <div class="toolbar">
        <a href="tel:02888768888"><i class="iconfont icon-phone"></i></a>
        <!-- <a href="javascript:;" @click="toMessage"><i class="iconfont icon-message"></i></a> -->
      </div>
    </div>
    <div class="main-menu" :class="{ close: openMenu==false }"  v-if="$route.path != '/'">
      <div class="menu">
        <a class="home" @click="toggleMenu" href="javascript:;"><i class="iconfont icon-hudongquwei"></i></a>
        <div class="mask">
          <a class="item" @click="toggleMenu" href="javascript:;"><i class="iconfont icon-arrow"></i></a>
          <router-link class="item" to="/zone" :class="{ active: $route.path == '/zone'}">
            <i class="iconfont icon-hudongquwei"></i>
            <span>互动区位</span>
          </router-link>
          <router-link class="item" to="/sand" :class="{ active: $route.path == '/sand'}">
            <i class="iconfont icon-sand"></i>
            <span>3D沙盘</span>
          </router-link>
          <router-link class="item" to="/plan" :class="{ active: $route.path == '/plan'}">
            <i class="iconfont icon-plan"></i>
            <span>项目规划</span>
          </router-link>
          <router-link class="item" to="/vr" :class="{ active: $route.path == '/vr'}">
            <i class="iconfont icon-vr"></i>
            <span>VR漫游</span>
          </router-link>
          <router-link class="item" to="/house" :class="{ active: $route.path == '/house'}">
            <i class="iconfont icon-house"></i>
            <span>3D户型</span>
          </router-link>
          <router-link class="item" to="/video" :class="{ active: $route.path == '/video'}">
            <i class="iconfont icon-video"></i>
            <span>三维片</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="sidemenu" v-if="$route.path != '/'">
      <a class="item" href="javascript:;" @click="toggleSideMenu" v-if="openSideMenu == false"><i class="iconfont icon-menu"></i></a>
      <div class="mask" v-else>
        <a class="item" href="javascript:;" @click="toggleSideMenu"><i class="iconfont icon-close"></i></a>
        <a class="item" href="javascript:;" @click="playmusic"><i v-if="isplay" class="iconfont cw icon-music"></i><i v-else class="iconfont cw icon-music-off"></i></a>
        <a class="item" href="javascript:;" @click="openmap"><i class="iconfont cw icon-navigation"></i></a>
        <router-link class="item" to="/book"><i class="iconfont cw icon-book"></i></router-link>
        <a class="item" href="javascript:;" @click="$store.state.openInfo = true"><i class="iconfont cw icon-info"></i></a>
      </div>
    </div>
    <!-- <transition name="fade"> -->
      <router-view />
    <!-- </transition> -->
    <info v-if="$store.state.openInfo"></info>
    <div class="qr-panel" v-if="showQR" @click="showQR=false">
      <img class="qr" :src="qr">
    </div>
    <div style="display:none">
      <audio src="https://dotway.oss-cn-beijing.aliyuncs.com/ncjf/audio.mp3" loop="loop" ref="audio"></audio>
    </div>
    <div class="tips" v-if="$route.path != '/'">
				温馨提示：沙盘中各部分经简化处理后，<br>微缩比例不完全一致，可能存在局部位置视觉差异。<br>本项目最终建成效果可能会与模型展示效果有差异。
			</div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import info from '@/components/info'

export default {
  name: "App",
  data() {
    return {
      iswxmp: false,
      mainMenu: "zone",
      openSideMenu: false,
      agent: null,
      showQR: false,
      qr: require('@/assets/images/share.jpg'),
      isplay: false
    };
  },
  components: {
    info
  },
  computed: mapState([
    'openMenu'
  ]),
  mounted(){
    if(location.pathname.indexOf('/agent/') != -1){
      var uuid = location.pathname.substring(7)
      console.log('uuid:',uuid)
      this.getAgent(uuid)
    }
    // this.getAgent()
    wx.miniProgram.getEnv((res) => {  // eslint-disable-line 
      console.log(res.miniprogram)
      this.iswxmp = true 
      setTimeout(()=>{
        this.playmusic()
      },500)
    })

    document.addEventListener("visibilitychange", () => { 
      if(document.hidden) {
      // 页面被挂起,暂停播放
        this.isplay = false
        this.$refs.audio.pause()
      }else {
      // 页面呼出
      }
    });
  },
  methods: {
    ...mapMutations([
      'toggleMenu'
    ]),
    toggleSideMenu(){
      if(this.openSideMenu){
        this.openSideMenu = false
      }else{
        this.openSideMenu = true
      }
    },
    openmap(){
      console.log('openmap')
      wx.openLocation({ // eslint-disable-line 
          latitude: 30.626252,
          longitude: 104.029262,
          name: '南山凌峯', // 位置名
          success: function () {
              console.log('success');
          }
      });
    },
    getAgent(uuid){
      this.$http.get('/agent/'+uuid).then((response)=>{
        console.log(response)
        this.agent = response.data.agent
        this.qr = '/api/agent/qr/' + this.agent.id
      })
    },
    playmusic(){
      if(this.isplay){
        this.isplay = false
        this.$refs.audio.pause()
      }else{
        this.isplay = true
        this.$refs.audio.play()
      }
    },
    toMessage(){
      wx.miniProgram.navigateTo({ url: '/pages/user/agentlist'}) // eslint-disable-line 
    },
    GetRequest() {
				var url = location.search; //获取url中"?"符后的字串
				var theRequest = new Object(); 
				if(url.indexOf("?") != -1) {    
					var str = url.substr(1);    
					var strs = str.split("&");    
					for(var i = 0; i < strs.length; i++) {      
						theRequest[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1]);    
					}  
				}  
				return theRequest;
			}
  },
};
</script>
<style lang="scss">
@import "assets/styles/common.scss";
@import "assets/styles/base.scss";

.qr-panel{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999;
  background: rgba(0,0,0,0.8);
  
  .qr{
    display: inline-block;
    max-width: 90%;
    max-height: calc(100vh - 60px);
    margin-top: 50px;
  }
}
.tips{
		position: absolute;
		right: 10px;
		bottom: 100px;
		color: #fff;
		font-size: 8px;
		letter-spacing: 1px;
		opacity: .6;
		writing-mode: vertical-lr;
    z-index: 9999;
	}
</style>
