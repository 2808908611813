<template>
  <!-- 启动页 -->
  <div class="home-page">
    <div class="logo"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: "Home",
	data (){
		return{
		}
	},
	components: {},
	computed: mapState([
    'openMenu'
	]),
	mounted(){
		setTimeout(() => {
			this.$router.push('vr')
		}, 4000);
	},
	methods:{
	}
};
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";

.home-page {
  position: absolute;
  // width: 100%;
  // height: 100vh;
	@include bg("home.jpg", 100%, 100vh, top center);

	.logo{
		position: absolute;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		@include bg("logo.gif", 360px, 202px, top center);
	}
}
</style>
