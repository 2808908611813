import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/zone',
    name: 'Zone',
    component: () => import(/* webpackChunkName: "zone" */ '../views/Zone.vue')
  },
  {
    path: '/sand',
    name: 'Sand',
    component: () => import(/* webpackChunkName: "sand" */ '../views/Sand.vue')
  },
  {
    path: '/vr',
    name: 'VR',
    component: () => import(/* webpackChunkName: "vr" */ '../views/VR.vue')
  },
  {
    path: '/house',
    name: 'House',
    component: () => import(/* webpackChunkName: "house" */ '../views/House.vue')
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import(/* webpackChunkName: "plan" */ '../views/Plan.vue')
  },
  {
    path: '/book',
    name: 'Book',
    component: () => import(/* webpackChunkName: "book" */ '../views/Book.vue')
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import(/* webpackChunkName: "video" */ '../views/Video.vue')
  },
  {
    path: '/agentlist',
    name: 'AgentList',
    component: () => import(/* webpackChunkName: "message" */ '../views/Message/AgentList.vue')
  },
  {
    path: '/message/:id',
    name: 'Message',
    component: () => import(/* webpackChunkName: "message" */ '../views/Message/Message.vue')
  },
  {
    path: '/soon',
    name: 'Soon',
    component: () => import(/* webpackChunkName: "soon" */ '../views/Soon.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
