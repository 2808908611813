import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openMenu: true,
    openInfo: false
  },
  mutations: {
    toggleMenu(state){
      if(state.openMenu){
        state.openMenu = false
      }else{
        state.openMenu = true
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
